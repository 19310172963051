import * as R from 'ramda'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulDocumentTemplatePropTypes,
  locationPropTypes,
} from 'helpers/propTypes'

const Document = ({data, location}) => {
  const {url} = R.pathOr(
    null,
    ['allContentfulDocument', 'nodes', 0, 'file', 'file'],
    data,
  )
  const {description, title} = R.pathOr(
    null,
    ['allContentfulDocument', 'nodes', 0, 'file'],
    data,
  )

  const metaContent = R.isEmpty(description) ? title : description

  const {href} = location

  const hideFromSearchEngines = title === 'White Paper - Clinical Data Fabric'

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {hideFromSearchEngines && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta name="description" content={metaContent} />
        <link rel="canonical" href={href} />
      </Helmet>
      <div
        style={{
          overflow: 'hidden',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <iframe
          src={url}
          title="title"
          style={{width: '100%', height: '100vh'}}
        />
      </div>
    </>
  )
}

Document.propTypes = {
  data: shape({
    allContentfulDocument: allContentfulDocumentTemplatePropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
}

export const pageQuery = graphql`
  query pageQuery($contentId: String) {
    allContentfulDocument(filter: {contentful_id: {eq: $contentId}}) {
      nodes {
        __typename
        file {
          description
          title
          file {
            url
          }
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default Document
